import { reduce } from 'lodash'

const createBoardConfig = (id, lanes) => {
  return {
    [id]: {
      id: id,
      lanes: lanes,
      lanesMap: reduce(lanes, (acc, lane) => {
        acc[lane.id] = lane
        return acc
      }, {}),
      dotsMap: reduce(lanes, (acc, lane) => {
        acc[lane.id] = lane.dot
        return acc
      }, {}),
    }
  }
}

export const templates = {
  ...createBoardConfig('went_well', [
    { id: 'went_well', title: 'What Went Well', dot: 'success' },
    { id: 'not_went_well', title: 'What Didn\'t go Well', dot: 'error' },
  ]),

  ...createBoardConfig('mad_sad_glad', [
    { id: 'mad', title: 'Mad', dot: 'error' },
    { id: 'sad', title: 'Sad', dot: 'warning' },
    { id: 'glad', title: 'Glad', dot: 'success' },
  ]),

  ...createBoardConfig('start_stop_continue', [
    { id: 'start_doing', title: 'Suggestion' || 'Start Doing', dot: 'warning' },
    { id: 'stop_doing', title: 'Need improvement' || 'Stop Doing', dot: 'error' },
    { id: 'continue_doing', title: 'Worked well' || 'Continue Doing', dot: 'success' },
  ]),

  ...createBoardConfig('4ls', [
    { id: 'liked', title: 'Liked', dot: 'success' },
    { id: 'learned', title: 'Learned', dot: 'success' },
    { id: 'lacked', title: 'Lacked', dot: 'warning' },
    { id: 'longed_for', title: 'Longed For', dot: 'default' },
  ]),

  ...createBoardConfig('lean_coffee', [
    { id: 'to_discuss', title: 'To Discuss', dot: 'warning' },
    { id: 'discussion', title: 'Discussion', dot: 'success' },
    { id: 'discussed', title: 'Discussed', dot: 'default' },
  ]),

  ...createBoardConfig('wishes_risks_appreciations_puzzles', [
    { id: 'wishes', title: 'Wishes', dot: 'warning' },
    { id: 'risks', title: 'Risks', dot: 'error' },
    { id: 'appreciations', title: 'Appreciations', dot: 'success' },
    { id: 'puzzles', title: 'Puzzles', dot: 'secondary' },
  ]),

  ...createBoardConfig('sprint_goal', [
    { id: 'increase_value', title: 'How do we increase value/utility?', dot: 'warning' },
    { id: 'helpers', title: 'Helpers', dot: 'warning' },
    { id: 'proposed_sprint_goal', title: 'Proposed Sprint Goal', dot: 'warning' },
  ]),
}